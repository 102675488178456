@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Inter:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Average+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", "Bona Nova", apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
}

.swiper {
  width: 100vw;
  height: calc(100vh - 200px);
  overflow: hidden;
}

.coverSlideItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallSwiperItem {
  height: 100px;
}
